import { FC, useEffect, useState } from "react"
import { Button, Grid, Menu, MenuItem } from "@mui/material"
import { Confirm } from "@components/shared/Confirm"
import { useCopyOrganization, useDeleteOrganizations, useExportAsCsv, useNewOrganization } from "@api/organizations"
import { usePatchManyOrganizations } from "@api/admin/organizations"
import { CsvUploadModal } from "./CsvUploadModal"
import { Link } from "react-router-dom"
import { UpdateChannelTypeDialog, UpdateChannelTypeMenuItem } from "./UpdateChannelType"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import { BatchUpdateDialog } from "./BatchUpdateDialog"
import { useIsSuperAdmin } from "@api/users"
import {
  useCreateMeetingsInBackground,
  useIdentifySpeakers,
  useEnableManyOppAnalysis,
  useDisableManyOppAnalysis,
  useEnableManySpeakerIdentification,
  useDisableManySpeakerIdentification,
} from "@api/admin/organizations"
import { useToast } from "@components/shared/ToastHook"

const batchUpdateKey = "ORG_BATCH_UPDATE_KEY"

export const ActionsMenu: FC<{
  orgIds: number[]
  onActionComplete: () => void
}> = ({ orgIds, onActionComplete }) => {
  const openBatchUpdate = useOpenModalKey(batchUpdateKey)
  const disableActions = orgIds.length === 0
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [openAutoUpdateConfirm, setOpenAutoUpdateConfirm] = useState("")
  const [openSpamEnabledUpdateConfirm, setOpenSpamEnabledUpdateConfirm] = useState("")
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const toast = useToast()
  const { mutate: deleteOrganizations } = useDeleteOrganizations()
  const { mutate: newOrganization } = useNewOrganization()
  const { mutate: updateOrganization } = usePatchManyOrganizations()
  const { mutate: copyOrganization } = useCopyOrganization()
  const { mutate: exportToCsv } = useExportAsCsv()
  const { mutate: createMeetingsInBackground, isSuccess } = useCreateMeetingsInBackground()
  const { mutate: identifySpeakers } = useIdentifySpeakers(() => toast("Speaker Identification requests sent"))
  const { mutate: enableManyOppAnalysis } = useEnableManyOppAnalysis(() => toast("Opportunity Analysis Enabled"))
  const { mutate: disableManyOppAnalysis } = useDisableManyOppAnalysis(() => toast("Opportunity Analysis Disabled"))
  const { mutate: enableManySpeakerIdentification } = useEnableManySpeakerIdentification({
    onSuccess: () => toast("Speaker Identification Enabled"),
  })
  const { mutate: disableManySpeakerIdentification } = useDisableManySpeakerIdentification({
    onSuccess: () => toast("Speaker Identification Disabled"),
  })

  useEffect(() => {
    if (isSuccess) {
      toast("Meetings created in background")
      onActionComplete()
      handleClose()
    }
  }, [isSuccess])

  const noStyle = {
    textDecoration: "none",
    color: "text.primary",
  }
  const linkButtonStyle = {
    ...noStyle,
    "&:active": noStyle,
    "&:focus": noStyle,
  }

  return (
    <Grid>
      <Button
        id="actions-menu-button"
        aria-controls="actions-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="medium"
        variant="outlined"
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "actions-menu-button" }}
      >
        <MenuItem component={Link} to="channels/wizard" sx={linkButtonStyle}>
          Wizard
        </MenuItem>
        <MenuItem onClick={() => createMeetingsInBackground(orgIds)} disabled={disableActions}>
          Create Meetings in Background
        </MenuItem>
        {isSuperAdmin && [
          <MenuItem key="new_channel" onClick={() => newOrganization(undefined)} disabled={!disableActions}>
            New Channel
          </MenuItem>,
          <MenuItem
            key="copy_channel"
            onClick={() => copyOrganization({ orgId: orgIds[0] })}
            disabled={orgIds.length !== 1}
          >
            Copy Channel
          </MenuItem>,
          <MenuItem key="auto_update_on" onClick={() => setOpenAutoUpdateConfirm("on")} disabled={disableActions}>
            Turn Auto Update On
          </MenuItem>,
          <MenuItem key="auto_update_off" onClick={() => setOpenAutoUpdateConfirm("off")} disabled={disableActions}>
            Turn Auto Update Off
          </MenuItem>,
          <MenuItem
            key="spam_enabled_on"
            onClick={() => setOpenSpamEnabledUpdateConfirm("on")}
            disabled={disableActions}
          >
            Turn Spam Enabled On
          </MenuItem>,
          <MenuItem
            key="spam_enabled_off"
            onClick={() => setOpenSpamEnabledUpdateConfirm("off")}
            disabled={disableActions}
          >
            Turn Spam Enabled Off
          </MenuItem>,
          <MenuItem key="delete_channel" onClick={() => setOpenConfirm(true)} disabled={disableActions}>
            Delete Channels(s)
          </MenuItem>,
          <UpdateChannelTypeMenuItem key="update_channel" orgIds={orgIds} />,
          <MenuItem key="upload_csv" onClick={() => setOpenUploadModal(true)}>
            Upload via CSV
          </MenuItem>,
          <MenuItem key="update_csv" onClick={openBatchUpdate}>
            Update via CSV
          </MenuItem>,
          <MenuItem key="export_csv" onClick={() => exportToCsv({ orgIds })}>
            Export Table to CSV
          </MenuItem>,
          <MenuItem
            key="identify_speakers"
            onClick={() => {
              identifySpeakers(orgIds)
              handleClose()
            }}
          >
            Identify Speakers
          </MenuItem>,
          <MenuItem
            key="enable_opp_analysis"
            onClick={() => {
              enableManyOppAnalysis(orgIds)
              handleClose()
            }}
            disabled={disableActions}
          >
            Enable Opportunity Analysis
          </MenuItem>,
          <MenuItem
            key="disable_opp_analysis"
            onClick={() => {
              disableManyOppAnalysis(orgIds)
              handleClose()
            }}
            disabled={disableActions}
          >
            Disable Opportunity Analysis
          </MenuItem>,
          <MenuItem
            key="enable_speaker_identification"
            onClick={() => {
              enableManySpeakerIdentification(orgIds)
              handleClose()
            }}
            disabled={disableActions}
          >
            Enable Speaker Identification
          </MenuItem>,
          <MenuItem
            key="disable_speaker_identification"
            onClick={() => {
              disableManySpeakerIdentification(orgIds)
              handleClose()
            }}
            disabled={disableActions}
          >
            Disable Speaker Identification
          </MenuItem>,
          <MenuItem
            key="ai_contact_discovery"
            onClick={() => {
              toast(`Coming soon: AI Contact Discovery. ${orgIds.length} organizations will be processed.`)
              handleClose()
            }}
            disabled={disableActions}
          >
            Run AI Contact Discovery
          </MenuItem>,
        ]}
      </Menu>
      <Confirm
        onSucceed={() => {
          deleteOrganizations({ orgIds })
          handleClose()
          setOpenConfirm(false)
          onActionComplete()
        }}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        title="Delete meeting(s)"
        body="Are you sure you want to delete these meetings? This action is irreversible."
      />
      <Confirm
        onSucceed={() => {
          updateOrganization({
            ids: orgIds,
            attributes: { automatic_update: openAutoUpdateConfirm === "on" ? true : false },
          })
          handleClose()
          onActionComplete()
          setOpenAutoUpdateConfirm("")
        }}
        onClose={() => setOpenAutoUpdateConfirm("")}
        open={openAutoUpdateConfirm !== ""}
        title="Auto Update, Update"
        body={`Are you sure you want to turn auto update ${openAutoUpdateConfirm}?`}
      />
      <Confirm
        onSucceed={() => {
          updateOrganization({
            ids: orgIds,
            attributes: { spam_enabled: openSpamEnabledUpdateConfirm === "on" ? true : false },
          })
          handleClose()
          onActionComplete()
          setOpenSpamEnabledUpdateConfirm("")
        }}
        onClose={() => setOpenSpamEnabledUpdateConfirm("")}
        open={openSpamEnabledUpdateConfirm !== ""}
        title="Spam Enabled, Update"
        body={`Are you sure you want to turn spam enabled ${openSpamEnabledUpdateConfirm}?`}
      />
      <CsvUploadModal open={openUploadModal} onClose={() => setOpenUploadModal(false)} />
      <UpdateChannelTypeDialog
        orgIds={orgIds}
        onClose={() => {
          onActionComplete()
          handleClose()
        }}
      />
      <BatchUpdateDialog modalKey={batchUpdateKey} handleClose={handleClose} />
    </Grid>
  )
}
