import { createContext, useState, ReactNode } from "react"
import {
  AiChainRunnable,
  AiChainRunnableSegmentTypes,
  OrchestratorTypes,
  Prompt,
  PromptInputTypes,
} from "@interfaces/ai-products/models"
import { PromptingResponse } from "@api/admin/ai-products/briefings"

interface ContextProps {
  configuration: FunnelOrchestratorEditor
  updateState: (newState: FunnelOrchestratorEditor) => void
}

export type InitialSegment = AiChainRunnable[]
export type MiddleSegment = AiChainRunnable[]
export type TailSegment = AiChainRunnable | null

export interface FunnelOrchestratorEditor {
  initialSegment: InitialSegment
  middleSegment: MiddleSegment
  tailSegment: TailSegment
  orchestratorType: OrchestratorTypes.FUNNEL
}

const defaultFunnelOrchestrator: FunnelOrchestratorEditor = {
  initialSegment: [] as InitialSegment,
  middleSegment: [] as MiddleSegment,
  tailSegment: null as TailSegment,
  orchestratorType: OrchestratorTypes.FUNNEL,
}

export const editablePrompt: (chainName: string) => Prompt = (chainName) => ({
  id: undefined,
  name: chainName,
  text: "",
})

export const editableInitialAiChain: (chainName: string) => AiChainRunnable = (chainName) => ({
  ai_prompt: editablePrompt(chainName),
  ai_model: { name: "gemini-2.0-flash-exp" },
  output_formatter: { name: "String Ouput" },
  segment_type: AiChainRunnableSegmentTypes.INITIAL, // need to calculate if total
  ordinal_number: 0,
  prompt_input: {
    values: [],
    input_type: PromptInputTypes.MEETING_IDS,
  },
})

export const FunnelOrchestratorContext = createContext<ContextProps>({
  configuration: defaultFunnelOrchestrator,
  updateState: () => {},
})

export const FunnelContext: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [configuration, setConfiguration] = useState<FunnelOrchestratorEditor>(defaultFunnelOrchestrator)

  const updateState = (newState: FunnelOrchestratorEditor) => {
    setConfiguration({ ...newState })
  }

  return (
    <FunnelOrchestratorContext.Provider value={{ configuration, updateState }}>
      {children}
    </FunnelOrchestratorContext.Provider>
  )
}

interface DisplayData {
  [key: string]: PromptingResponse
}
interface DisplayContextProps {
  displayData: { [key: string]: PromptingResponse }
  updateState: (modalKey: string, newDisplayData: PromptingResponse) => void
}

const defaultDisplayData: DisplayData = {}

export const DisplayDataContext = createContext<DisplayContextProps>({
  displayData: defaultDisplayData,
  updateState: () => {},
})

export const DisplayContext: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [displayData, setDisplayData] = useState<DisplayData>(defaultDisplayData)

  const updateState = (modalKey: string, newDisplayData: PromptingResponse) => {
    const currentDisplayData = { ...displayData }
    setDisplayData({ ...currentDisplayData, [modalKey]: newDisplayData })
  }

  return <DisplayDataContext.Provider value={{ displayData, updateState }}>{children}</DisplayDataContext.Provider>
}
