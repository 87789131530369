import { TextField, Chip, Stack, Typography } from "@mui/material"
import { FC, SetStateAction, useContext, useState } from "react"
import { FunnelOrchestratorContext } from "../../Context"
import { EditChainRunnableInterface } from "../AiChainEditModal"
import { updateAiChainRunnable } from "../../FunnelOrchestrator/aiChainRunnableCrud"
import { AiChainRunnable } from "@interfaces/ai-products/models"

export const MeetingIdInput: FC<EditChainRunnableInterface> = ({ aiChainRunnable, segmentType, index }) => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)
  const update = (aiChainRunnableEditable: AiChainRunnable) => {
    const newConfig = updateAiChainRunnable(configuration, index, aiChainRunnableEditable, segmentType)
    updateState(newConfig)
  }

  const [value, setValue] = useState("")

  const handleEnter = (e: { key: string }) => {
    if (e.key === "Enter" && value !== "" && !aiChainRunnable.prompt_input.values.includes(value)) {
      update({
        ...aiChainRunnable,
        prompt_input: {
          ...aiChainRunnable.prompt_input,
          values: [...aiChainRunnable.prompt_input.values, value],
        },
      })
      setValue("")
    }
  }

  const handleValueChange = (e: { target: { value: SetStateAction<string> } }) => {
    setValue(e.target.value)
  }

  const handleValueDelete = (item: string) => {
    update({
      ...aiChainRunnable,
      prompt_input: {
        ...aiChainRunnable.prompt_input,
        values: aiChainRunnable.prompt_input.values.filter((v) => v !== item),
      },
    })
  }

  return (
    <Stack spacing={1} direction="column">
      <Typography variant="body2">Example Data</Typography>
      <TextField
        label="Meeting Ids"
        size="small"
        fullWidth
        multiline
        minRows={3}
        autoFocus
        onKeyUp={handleEnter}
        value={value}
        onChange={handleValueChange}
        InputProps={{
          startAdornment:
            aiChainRunnable.prompt_input.values.length === 0
              ? null
              : aiChainRunnable.prompt_input.values.map((v) => {
                  return (
                    <Chip
                      sx={{ margin: "2px", fontSize: "0.75rem" }}
                      key={v}
                      label={v}
                      size="medium"
                      variant="filled"
                      color="primary"
                      onDelete={() => {
                        handleValueDelete(v)
                      }}
                    />
                  )
                }),
          style:
            aiChainRunnable.prompt_input.values.length === 0
              ? {}
              : {
                  display: "inline-block",
                  margin: 2,
                },
        }}
      />
    </Stack>
  )
}
