import { AiChainRunnable, PromptInputTypes } from "@interfaces/ai-products/models"

export class AiChainRunnableValidator {
  aiChainRunnable

  constructor(aiChainRunnable: AiChainRunnable) {
    this.aiChainRunnable = aiChainRunnable
  }

  validateName(): boolean {
    return !!this.aiChainRunnable.ai_prompt.name
  }

  validateInputData(): boolean {
    if (this.aiChainRunnable.prompt_input.input_type === PromptInputTypes.CHAIN_OUTPUT) {
      return true
    }
    return !!this.aiChainRunnable.prompt_input.values.length
  }

  validatePrompt(): boolean {
    return !!this.aiChainRunnable.ai_prompt.text
  }
}
