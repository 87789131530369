import { Typography, Autocomplete, TextField, Stack } from "@mui/material"
import { FC, useContext, useState } from "react"
import { EditablePrompt } from "../AiChainEditModal"
import { AiChainRunnable } from "@interfaces/ai-products/models"
import { getPrompts } from "@api/admin/prompts"
import { updateAiChainRunnable } from "../../FunnelOrchestrator/aiChainRunnableCrud"
import { FunnelOrchestratorContext } from "../../Context"
import { isNewPrompt } from "./promptEditing"
import { EditChainRunnableInterface } from "../AiChainEditModal"
import { editablePrompt } from "../../Context"

export const EditChainRunnable: FC<EditChainRunnableInterface> = ({ aiChainRunnable, segmentType, index }) => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)
  const { data: prompts } = getPrompts()
  const aiChainRunnableEditable = { ...aiChainRunnable }
  const [newPromptName, setNewPromptName] = useState<string>(aiChainRunnableEditable?.ai_prompt?.name)

  const update = (aiChainRunnableEditable: AiChainRunnable) => {
    const newConfig = updateAiChainRunnable(configuration, index, aiChainRunnableEditable, segmentType)
    updateState(newConfig)
  }

  const customPromptOption: EditablePrompt = {
    ...editablePrompt(aiChainRunnableEditable.ai_prompt.name),
    is_personal: false,
    italicize: true,
  }

  const promptOptions = prompts ? ([customPromptOption, ...prompts] as EditablePrompt[]) : ([] as EditablePrompt[])

  return (
    <Stack spacing={1} direction="column">
      <Typography variant="h5" component="span">
        Prompt
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        options={promptOptions}
        renderOption={(props, option: EditablePrompt) => {
          return (
            <li {...props}>
              <Typography sx={option.italicize ? { fontStyle: "italic" } : {}}>
                {option.name}
                {}
                {option.italicize ? "..." : ""}
              </Typography>
            </li>
          )
        }}
        sx={{ width: 300 }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Options" />}
        value={
          !isNewPrompt(prompts, aiChainRunnableEditable.ai_prompt)
            ? prompts?.find((prompt) => aiChainRunnableEditable.ai_prompt.text === prompt.text)
            : null
        }
        onChange={(e, value) => {
          update({ ...aiChainRunnableEditable, ai_prompt: value || customPromptOption })
        }}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Name"
        variant="outlined"
        disabled={!isNewPrompt(prompts, aiChainRunnableEditable.ai_prompt)}
        value={aiChainRunnableEditable?.ai_prompt?.name}
        onChange={(e) => {
          setNewPromptName(e.target.value)
          update({
            ...aiChainRunnableEditable,
            ai_prompt: { ...aiChainRunnableEditable.ai_prompt, name: e.target.value },
          })
        }}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Text"
        variant="outlined"
        multiline
        value={aiChainRunnableEditable?.ai_prompt?.text}
        onChange={(e) => {
          update({
            ...aiChainRunnableEditable,
            ai_prompt: {
              ...aiChainRunnableEditable.ai_prompt,
              text: e.target.value,
              name: newPromptName,
            },
          })
        }}
        rows={10}
      />
    </Stack>
  )
}
