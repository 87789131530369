import { Box, Button, Stack, Typography } from "@mui/material"
import { FC, useContext, useState } from "react"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import { DisplayDataContext, FunnelOrchestratorContext } from "../../Context"
import { runBriefing } from "@api/admin/ai-products/briefings"
import { useAuthAxios } from "@api/axiosInstance"
import { buildPromptingRequest } from "@api/admin/ai-products/bodyBuilder"
import { EditChainRunnableInterface, getModalKey } from "../AiChainEditModal"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PromptingDisplay: FC<EditChainRunnableInterface> = ({ aiChainRunnable, segmentType, index }) => {
  const { configuration } = useContext(FunnelOrchestratorContext)
  const axiosInstance = useAuthAxios()
  const { displayData, updateState } = useContext(DisplayDataContext)
  const modalKey = getModalKey(index, segmentType, configuration)
  const [isLoading, setIsLoading] = useState(false)
  const errorDisplay = <Typography>Something went wrong...</Typography>
  const [isError, setIsError] = useState(false)

  const run = async () => {
    setIsError(false)
    setIsLoading(true)
    try {
      const response = await runBriefing(axiosInstance, buildPromptingRequest(configuration, segmentType, index))
      updateState(modalKey, response)
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const responseTitleToMeetingIds = (responseTitle: string) => {
    const re = /-(.*)/
    const meetingId = responseTitle.match(re)
    return `Meeting Id: ${meetingId ? meetingId[1] : "Unknown"}`
  }

  const promptResponse = displayData[modalKey]?.response

  let display = [<Typography variant="h3">No output</Typography>]
  if (promptResponse?.multiple_responses) {
    display = Object.entries(promptResponse.multiple_responses).map(([key, value]) => {
      return (
        <Stack key={key} direction="column" spacing={1} marginTop={2}>
          <Typography variant="h3">{responseTitleToMeetingIds(key)}</Typography>
          <Typography variant="body1">{value}</Typography>
        </Stack>
      )
    })
  } else if (promptResponse?.single_response) {
    display = [<Typography variant="body1">{promptResponse.single_response}</Typography>]
  }

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">Output</Typography>
        <Button onClick={run} sx={{ width: "20%" }} variant="contained" endIcon={<PlayCircleIcon />}>
          Run
        </Button>
      </Stack>

      <Box sx={{ height: "50vh", border: "1px solid black", padding: 2, overflow: "auto" }}>
        {isLoading ? <Typography>Loading...</Typography> : isError ? errorDisplay : display}
      </Box>
    </Stack>
  )
}
