export enum OrchestratorTypes {
  FUNNEL = "funnel",
}

export enum AiChainRunnableSegmentTypes {
  INITIAL = "initial",
  MIDDLE = "middle",
  TAIL = "tail",
  TOTAL = "total", // if first segment == last segment
}

export enum PromptInputTypes {
  TEXT = "text",
  MEETING_IDS = "meeting_ids",
  URLS = "urls",
  CHAIN_OUTPUT = "chain_output",
}

export interface Prompt {
  id?: number
  name: string
  text: string
}

export interface AiModel {
  name: string
}

export interface OutputFormatter {
  name: string
}

export interface AiChainRunnable {
  id?: number
  ai_prompt: Prompt
  ai_model: AiModel
  output_formatter: OutputFormatter | null
  segment_type: AiChainRunnableSegmentTypes
  ordinal_number: number
  prompt_input: PromptInputData // should not get saved
}

export interface PromptInputData {
  values: string[]
  input_type: PromptInputTypes
}

export interface RawAiChainRunnables {
  ai_chain_runnable: AiChainRunnable
  prompt_input: PromptInputData | null
}

export interface PromptingRequest {
  ai_chain_runnables: AiChainRunnable[]
  orchestrator_type: OrchestratorTypes
}
