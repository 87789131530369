import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, DialogContent, Stack } from "@mui/material"
import { FC, useContext } from "react"
import { FunnelOrchestratorContext } from "../../Context"
import { AiChainRunnable, AiChainRunnableSegmentTypes, PromptInputTypes } from "@interfaces/ai-products/models"
import { updateAiChainRunnable } from "../../FunnelOrchestrator/aiChainRunnableCrud"
import { getInputComponentByType, getMenuItemsBySegmentType } from "./inputTypeMapping"
import { EditChainRunnableInterface } from "../AiChainEditModal"

export const InputData: FC<EditChainRunnableInterface> = (editChainProps) => {
  const { aiChainRunnable, segmentType, index } = editChainProps
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)
  const aiChainRunnableEditable = { ...aiChainRunnable }
  const update = (aiChainRunnableEditable: AiChainRunnable) => {
    const newConfig = updateAiChainRunnable(configuration, index, aiChainRunnableEditable, segmentType)
    updateState(newConfig)
  }

  const updateSelect = (e: SelectChangeEvent<PromptInputTypes>): PromptInputTypes => {
    if (!e?.target?.value) {
      return PromptInputTypes.MEETING_IDS
    }
    return e.target.value as PromptInputTypes
  }

  return (
    <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Stack direction="column" spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="ai-chain-runnable-input-type">Input Type</InputLabel>
          <Select
            labelId="ai-chain-runnable-input-type"
            id="demo-simple-select"
            value={aiChainRunnable?.prompt_input?.input_type}
            label="Input Type"
            disabled={
              aiChainRunnableEditable.segment_type === AiChainRunnableSegmentTypes.MIDDLE ||
              aiChainRunnableEditable.segment_type === AiChainRunnableSegmentTypes.TAIL
            }
            onChange={(e: SelectChangeEvent<PromptInputTypes>) =>
              update({
                ...aiChainRunnableEditable,
                prompt_input: {
                  ...aiChainRunnableEditable.prompt_input,
                  input_type: updateSelect(e),
                  values: aiChainRunnableEditable.prompt_input?.values || [],
                },
              })
            }
          >
            {getMenuItemsBySegmentType(aiChainRunnableEditable.segment_type)}
          </Select>
        </FormControl>
        {getInputComponentByType(aiChainRunnable.prompt_input.input_type, editChainProps)}
      </Stack>
    </DialogContent>
  )
}
