import { OutlinedInput, FormControl, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { EditChainRunnableInterface } from "../AiChainEditModal"
import { FunnelOrchestratorContext } from "../../Context"
import { updateAiChainRunnable } from "../../FunnelOrchestrator/aiChainRunnableCrud"
import { AiChainRunnable } from "@interfaces/ai-products/models"
import { sizes } from "../stylesAndEffects"

export const NameInput: FC<EditChainRunnableInterface> = ({ aiChainRunnable, segmentType, index }) => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)
  const aiChainRunnableEditable = { ...aiChainRunnable }
  const update = (aiChainRunnableEditable: AiChainRunnable) => {
    const newConfig = updateAiChainRunnable(configuration, index, aiChainRunnableEditable, segmentType)
    updateState(newConfig)
  }

  return (
    <FormControl fullWidth variant="outlined">
      <Typography sx={{ padding: 1, alignSelf: "center", ...sizes("medium") }} variant="h2">
        Prompt Name
      </Typography>
      <OutlinedInput
        id="ai-prompt-name"
        sx={{ "#ai-prompt-name": sizes("small") as React.CSSProperties }}
        fullWidth
        onChange={(e) =>
          update({
            ...aiChainRunnableEditable,
            ai_prompt: { ...aiChainRunnableEditable.ai_prompt, name: e.target.value },
          })
        }
        value={aiChainRunnable.ai_prompt.name}
      />
    </FormControl>
  )
}
