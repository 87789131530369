import { Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { FunnelOrchestratorContext } from "./Context"
import { AiChainRunnable, AiChainRunnableSegmentTypes } from "@interfaces/ai-products/models"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import { getModalKey } from "./EditorWizard/AiChainEditModal"

export const AiChainRunnableDisplay: FC<{
  aiChainRunnable: AiChainRunnable
  deletePrompt: (index: number) => void
  index: number
  segmentType: AiChainRunnableSegmentTypes
}> = ({ deletePrompt, index, aiChainRunnable, segmentType }) => {
  const { configuration } = useContext(FunnelOrchestratorContext)
  const openEditModalKey = getModalKey(index, segmentType, configuration)
  const openModal = useOpenModalKey(openEditModalKey)

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 4,
        width: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 275,
      }}
    >
      <CardContent>
        <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
          Ordinal: {aiChainRunnable.ordinal_number}
        </Typography>
        <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
          Segment Type: {aiChainRunnable.segment_type}
        </Typography>
        <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
          Model: {aiChainRunnable.ai_model?.name || "None"}
        </Typography>
        <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
          Output Parser: {aiChainRunnable.output_formatter?.name || "None"}
        </Typography>
        <Typography variant="h5" component="div">
          Prompt Name: {aiChainRunnable.ai_prompt.name || "WIP"}
        </Typography>

        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "3",
            "-webkit-box-orient": "vertical",
            wordWrap: "break-word",
          }}
          variant="body2"
        >
          {aiChainRunnable.ai_prompt.text}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button disabled size="small">
          Save
        </Button>
        <Button size="small" onClick={openModal}>
          Edit
        </Button>
        <Button color="error" size="small" onClick={() => deletePrompt(index)}>
          Remove
        </Button>
      </CardActions>
    </Card>
  )
}
