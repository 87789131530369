import { FC, useContext, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Step,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material"
import { AiChainRunnable, AiChainRunnableSegmentTypes } from "@interfaces/ai-products/models"
import { useCloseModalKey, useModalKey } from "@components/shared/OpenModalHook"
import { dialogSx, sizes, Transition } from "./stylesAndEffects"
import CloseIcon from "@mui/icons-material/Close"
import { theme } from "@src/theme"
import { Prompt } from "@interfaces/prompt"
import { FunnelOrchestratorContext, FunnelOrchestratorEditor } from "../Context"
import { EditChainRunnable } from "./ChainRunnable/EditChainRunnable"
import { InputData } from "./InputData/InputData"
import { NameInput } from "./NameInput/NameInput"
import { LooksOne, LooksTwo, Looks3, Looks4 } from "@mui/icons-material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { AiChainRunnableValidator } from "@api/admin/ai-products/validations"
import { PromptingDisplay } from "./PromptingDisplay"

export interface EditablePrompt extends Partial<Prompt> {
  id?: number
  name: string
  text: string
  italicize?: boolean
}

export const getModalKey = (
  index: number,
  segmentType: AiChainRunnableSegmentTypes,
  configuration: FunnelOrchestratorEditor,
) => {
  let ordinal_number
  if (segmentType === AiChainRunnableSegmentTypes.INITIAL) {
    ordinal_number = configuration.initialSegment[index].ordinal_number
  } else if (segmentType === AiChainRunnableSegmentTypes.MIDDLE) {
    ordinal_number = configuration.middleSegment[index].ordinal_number
  } else {
    ordinal_number = configuration.tailSegment?.ordinal_number
  }

  return `aiChainRunnable-${index}-${segmentType}-${ordinal_number}`
}

export interface EditChainRunnableInterface {
  aiChainRunnable: AiChainRunnable
  segmentType: AiChainRunnableSegmentTypes
  index: number
}

export const AiChainEditModal: FC<EditChainRunnableInterface> = ({ aiChainRunnable, segmentType, index }) => {
  const { configuration } = useContext(FunnelOrchestratorContext)

  const modalKey = getModalKey(index, segmentType, configuration)
  const modalIsOpen = useModalKey(modalKey)
  const closeModal = useCloseModalKey(modalKey)
  const [activeStep, setActiveStep] = useState(0)

  const editRunnableProps = {
    aiChainRunnable: aiChainRunnable,
    segmentType: segmentType,
    index: index,
  }

  const stepConfig = [
    {
      label: "Choose a Name",
      component: <NameInput {...editRunnableProps} />,
      next: (
        <Button size="large" onClick={() => setActiveStep(1)} endIcon={<ArrowForwardIosIcon />}>
          Next
        </Button>
      ),
      ordinal: <LooksOne sx={sizes("medium")} />,
      nextPrecondition: new AiChainRunnableValidator(aiChainRunnable).validateName(),
    },
    {
      label: "Select Input Type",
      component: <InputData {...editRunnableProps} />,
      back: (
        <Button size="large" onClick={() => setActiveStep(0)} startIcon={<ArrowBackIosNewIcon />}>
          Back
        </Button>
      ),
      next: (
        <Button size="large" onClick={() => setActiveStep(2)} endIcon={<ArrowForwardIosIcon />}>
          Next
        </Button>
      ),
      nextPrecondition: new AiChainRunnableValidator(aiChainRunnable).validateInputData(),
      ordinal: <LooksTwo />,
    },
    {
      label: "Edit Prompt",
      component: <EditChainRunnable {...editRunnableProps} />,
      back: (
        <Button size="large" onClick={() => setActiveStep(1)} startIcon={<ArrowBackIosNewIcon />}>
          Back
        </Button>
      ),
      next: (
        <Button size="large" onClick={() => setActiveStep(3)} endIcon={<ArrowForwardIosIcon />}>
          Next
        </Button>
      ),
      nextPrecondition: new AiChainRunnableValidator(aiChainRunnable).validatePrompt(),
      ordinal: <Looks3 />,
    },
    {
      label: "Run Prompt",
      component: <PromptingDisplay {...editRunnableProps} />,
      back: (
        <Button onClick={() => setActiveStep(2)} startIcon={<ArrowBackIosNewIcon />}>
          Back
        </Button>
      ),
      next: <Button onClick={() => console.log("hi")}>Run</Button>,
      ordinal: <Looks4 />,
    },
  ]

  return (
    <Dialog open={modalIsOpen} fullWidth onClose={closeModal} TransitionComponent={Transition} sx={dialogSx}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          ".MuiStep-root": { padding: 0 },
          height: "inherit",
        }}
      >
        <Step key={stepConfig[activeStep].label}>
          <Toolbar
            sx={{
              backgroundColor: theme.palette.primary.light,
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <Stack color="white" direction="row" spacing={2} alignItems={"center"}>
              {stepConfig[activeStep].ordinal}
              <Typography variant="h3" color="white" component="span" sx={sizes("medium")}>
                {stepConfig[activeStep].label}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <IconButton
                edge="start"
                onClick={closeModal}
                aria-label="close"
                sx={{ color: "white", ...sizes("medium") }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Toolbar>
          <Stack direction={"column"}>
            <DialogActions
              sx={{
                justifyContent:
                  stepConfig[activeStep].back || !stepConfig[activeStep].next ? "space-between" : "flex-end",
                padding: 2,
              }}
            >
              {stepConfig[activeStep].back && stepConfig[activeStep].back}
              {stepConfig[activeStep].next && stepConfig[activeStep].nextPrecondition && stepConfig[activeStep].next}
              {stepConfig[activeStep].nextPrecondition && !stepConfig[activeStep].next && (
                <Button variant="outlined" color="primary" onClick={closeModal} sx={{ ...sizes("small") }}>
                  Save
                </Button>
              )}
            </DialogActions>
            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              {stepConfig[activeStep].component}
            </DialogContent>
          </Stack>
        </Step>
      </Stepper>
    </Dialog>
  )
}
