import { PromptingRequest } from "@interfaces/ai-products/models"
import { AxiosInstance } from "axios"

const resourcePath = "/minnie/ai-products/admin/briefings"

interface PromptingResponseTypes {
  multiple_responses: { [key: string]: string }
  single_response: string
}

export interface PromptingResponse {
  metadata: any
  response: PromptingResponseTypes
}

export const runBriefing = async (axiosInstance: Promise<AxiosInstance>, requestBody: PromptingRequest) => {
  const uri = `${resourcePath}/run`
  const response = await (await axiosInstance).post<PromptingResponse>(uri, requestBody)
  return response.data
}
