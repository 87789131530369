import { FunnelOrchestratorEditor } from "@components/Admin/AiProducts/Playground/Context"
import {
  AiChainRunnable,
  AiChainRunnableSegmentTypes,
  OrchestratorTypes,
  PromptingRequest,
} from "@interfaces/ai-products/models"

export const buildPromptingRequest: (
  configurations: FunnelOrchestratorEditor,
  segmentType: AiChainRunnableSegmentTypes,
  index?: number,
) => PromptingRequest = (configurations, segmentType, index) => {
  const requestConfigurations = structuredClone(configurations)

  if (index !== undefined) {
    if (segmentType === AiChainRunnableSegmentTypes.INITIAL) {
      requestConfigurations.initialSegment.forEach(
        (aiChainRunnable: AiChainRunnable) => (aiChainRunnable.segment_type = AiChainRunnableSegmentTypes.TOTAL),
      )
      requestConfigurations.tailSegment = null
    }

    if (segmentType === AiChainRunnableSegmentTypes.MIDDLE) {
      const tailSegmentSlice = requestConfigurations.middleSegment.slice(index, index + 1)
      if (tailSegmentSlice) {
        const tailSegment = tailSegmentSlice[0]
        tailSegment.segment_type = AiChainRunnableSegmentTypes.TAIL
        requestConfigurations.tailSegment = tailSegment
      }
      requestConfigurations.middleSegment = requestConfigurations.middleSegment.slice(0, index)
    }
  }
  const aiChainRunnables = [...requestConfigurations.initialSegment, ...requestConfigurations.middleSegment]
  if (requestConfigurations.tailSegment) {
    aiChainRunnables.push(requestConfigurations.tailSegment)
  }

  return {
    ai_chain_runnables: aiChainRunnables,
    orchestrator_type: OrchestratorTypes.FUNNEL,
  } as PromptingRequest
}
