import { FC, useContext } from "react"
import { Button, Stack, Typography } from "@mui/material"
import { ParallelRunnableRow } from "./ParallelRunnableRow"
import { SequentialRunnableRow } from "./SequentialRunnableRow"
import { RunButton } from "./RunButton"
import { AddCard } from "../AddCard"
import SaveIcon from "@mui/icons-material/Save"
import { addTailSegment, deleteMiddleSegment, deleteTailSegment } from "./FunnelOrchestrator/segmentCrud"
import { DisplayContext, editableInitialAiChain, FunnelOrchestratorContext } from "./Context"
import { AiChainRunnableSegmentTypes } from "@interfaces/ai-products/models"

export const Container: FC = () => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)

  const addPromptToTail = () => {
    const newConfiguration = addTailSegment(configuration, editableInitialAiChain(""))
    updateState(newConfiguration)
  }

  const deleteMiddleSegmentPrompt = (index: number) => {
    const newConfiguration = deleteMiddleSegment(configuration, index)
    updateState(newConfiguration)
  }

  const deleteTailSegmentPrompt = () => {
    const newConfiguration = deleteTailSegment(configuration, 0)
    updateState(newConfiguration)
  }

  return (
    <DisplayContext>
      <Stack
        direction="column"
        sx={{
          paddingLeft: 5,
          paddingBottom: 5,
          paddingRight: 5,
          backgroundColor: "white",
          borderLeft: "thick solid #d7d7d778",
        }}
      >
        <Stack direction="row" justifyContent="space-between" paddingTop={2}>
          <Stack direction="column" spacing={1} marginTop={1}>
            <Typography variant="h1" color="primary">
              AI Chain Playground
            </Typography>
            <Typography variant="h5" color="primary">
              Briefings - Solar Sentiment
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <RunButton />
            <Button variant="contained" size="medium" color="primary" endIcon={<SaveIcon />}>
              Save
            </Button>
          </Stack>
        </Stack>
        <Stack spacing={2} direction="column" paddingTop={4}>
          <ParallelRunnableRow />
          {configuration.middleSegment.length > 0 && (
            <SequentialRunnableRow
              deletePrompt={deleteMiddleSegmentPrompt}
              segmentType={AiChainRunnableSegmentTypes.MIDDLE}
            />
          )}
          {configuration.tailSegment && (
            <SequentialRunnableRow
              deletePrompt={deleteTailSegmentPrompt}
              segmentType={AiChainRunnableSegmentTypes.TAIL}
            />
          )}
          <AddCard actionText="Add to Chain" addAction={() => addPromptToTail()} />
        </Stack>
      </Stack>
    </DisplayContext>
  )
}
