import { FC } from "react"
import { FunnelContext } from "./Playground/Context"
import { Container } from "./Playground/Container"

export const AiProductsPage: FC = () => {
  return (
    <FunnelContext>
      <Container />
    </FunnelContext>
  )
}
