import { FC } from "react"
import { Button } from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"

export const RunButton: FC = () => {
  return (
    <Button size="medium" variant="outlined" endIcon={<PlayArrowIcon />}>
      Run Feature
    </Button>
  )
}
