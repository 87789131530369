import { FC } from "react"
import { Card, CardContent, Typography, Stack, CardActionArea } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

export const AddCard: FC<{
  actionText: string
  addAction: () => void
}> = ({ actionText, addAction }) => (
  <Card
    variant="outlined"
    onClick={() => addAction()}
    sx={{
      display: "flex",
      borderRadius: 4,
      width: 275,
      height: 265,
      border: "thick dotted #1b242db0",
    }}
  >
    <CardActionArea>
      <CardContent sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Stack width="100%" direction="column" spacing={3}>
          <AddCircleOutlineIcon fontSize="large" color="primary" />
          <Typography variant="h2" color="primary" sx={{ alignSelf: "center" }}>
            {actionText}
          </Typography>
        </Stack>
      </CardContent>
    </CardActionArea>
  </Card>
)
