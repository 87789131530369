import { FC, useContext } from "react"
import { AiChainRunnableDisplay } from "./AiChainRunnableDisplay"
import { RowContainer } from "./RowContainer"
import { AddCard } from "../AddCard"
import { editableInitialAiChain, FunnelOrchestratorContext } from "./Context"
import { addInitalSegment, deleteInitalSegment } from "./FunnelOrchestrator/segmentCrud"
import { AiChainRunnableSegmentTypes } from "@interfaces/ai-products/models"
import { AiChainEditModal } from "./EditorWizard/AiChainEditModal"

export const ParallelRunnableRow: FC = () => {
  const { configuration, updateState } = useContext(FunnelOrchestratorContext)

  const addPrompt = () => {
    const newConfiguration = addInitalSegment(configuration, editableInitialAiChain(""))
    updateState(newConfiguration)
  }

  const deletePrompt = (index: number) => {
    const newConfiguration = deleteInitalSegment(configuration, index)
    updateState(newConfiguration)
  }

  return (
    <RowContainer direction="row" spacing={2}>
      {configuration.initialSegment.map((segment, index) => (
        <>
          <AiChainRunnableDisplay
            deletePrompt={deletePrompt}
            index={index}
            key={`${index}-${AiChainRunnableSegmentTypes.INITIAL}-display`}
            aiChainRunnable={segment}
            segmentType={AiChainRunnableSegmentTypes.INITIAL}
          />
          <AiChainEditModal
            key={`${index}-${AiChainRunnableSegmentTypes.INITIAL}-modal`}
            index={index}
            aiChainRunnable={segment}
            segmentType={AiChainRunnableSegmentTypes.INITIAL}
          />
        </>
      ))}
      <AddCard actionText="Add Input Prompt" addAction={() => addPrompt()} />
    </RowContainer>
  )
}
