import { FC, useMemo, useState, useEffect } from "react"
import { Box, Grid, Link } from "@mui/material"
import { DataGridPro, GridColDef, GridSortModel, GridPaginationModel, GridSortDirection } from "@mui/x-data-grid-pro"
import { defaultCountyStateFilterParamsSingle, defaultCountyStateFilterParams } from "@components/shared/config"
import { OrganizationExtendedViews, useFetchOrganizations } from "@src/api/admin/organizations"
import { ActionsMenu } from "./ActionsMenu"
import { createOrganizationRow } from "@config/organizationAdminConfig"
import { TableFilters } from "./TableFilters"
import { FilterParamsSingle } from "../../Home/interface"
import { unpackSetters } from "@components/shared/unpackSetters"
import { IOrganization } from "@api/interfaces"
import { formatDateTime } from "@components/shared/DataTable"

interface OrganizationState {
  selectedOrgs: number[]
  filterParams: FilterParamsSingle
  nameFilter: string
  nameFilterQueryKey: string
  autoUpdateFilter: string
  archiveTypeFilter: string
  spamEnabledFilter: string
  tablePage: number
  pageSize: number
  channelTypeFilter: string
  archiveStatusFilter: string
  proxyShuffleFilter: string
  videoArchiveIdQueryKey: string
  videoArchiveIdFilter: string
  oppAnalysisEnabledFilter: string
  hasContactsFilter: string
  speakerIdentificationEnabledFilter: string
  // using a simple object for sort with a field name and order
  sortBy: { name: string; method: "asc" | "desc" }
}

export const OrganizationAdminContainer: FC = () => {
  const organizationProps: OrganizationState = {
    selectedOrgs: [],
    filterParams: defaultCountyStateFilterParamsSingle,
    nameFilter: "",
    nameFilterQueryKey: "",
    autoUpdateFilter: "",
    archiveTypeFilter: "",
    spamEnabledFilter: "",
    tablePage: 1,
    pageSize: 25, // DEFAULT PAGE SIZE
    channelTypeFilter: "",
    archiveStatusFilter: "",
    proxyShuffleFilter: "",
    videoArchiveIdQueryKey: "",
    videoArchiveIdFilter: "",
    oppAnalysisEnabledFilter: "",
    hasContactsFilter: "",
    speakerIdentificationEnabledFilter: "",
    sortBy: { name: "id", method: "desc" },
  }

  const [organizationState, setOrganizationState] = useState(organizationProps)

  const organizationColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 90,
    },
    {
      field: "name",
      headerName: "Channel",
      width: 200,
      renderCell(params) {
        return <Link href={`/admin/organizations/${params.row.id}`}>{params.row.name}</Link>
      },
    },
    {
      field: "automatic_update",
      headerName: "Auto Update",
      renderCell(params) {
        return <Box>{params.row.automatic_update ? "Yes" : "No"}</Box>
      },
    },
    {
      field: "shuffle_region",
      headerName: "Proxy Shuffle",
      renderCell(params) {
        return <Box>{params.row.shuffle_region ? "Yes" : "No"}</Box>
      },
    },
    {
      field: "channel_type",
      headerName: "Channel Type",
      width: 130,
    },
    {
      field: "state_name",
      headerName: "State",
    },
    {
      field: "county_name",
      headerName: "County",
    },
    {
      field: "city_name",
      headerName: "City",
    },
    {
      field: "video_archive_type",
      headerName: "Video Archive Type",
    },
    {
      field: "video_archive_id",
      headerName: "Video Archive Id",
    },
    {
      field: "home_page_url",
      headerName: "Home Page URL",
      width: 250,
      renderCell(params) {
        return <Link href={params.row.home_page_url}>{params.row.home_page_url}</Link>
      },
    },
    {
      field: "contact_page_url",
      headerName: "Contact Page URL",
      width: 250,
      renderCell(params) {
        return <Link href={params.row.contact_page_url}>{params.row.contact_page_url}</Link>
      },
    },
    {
      field: "last_scraped_at",
      headerName: "Last Finished",
      width: 200,
      renderCell(params) {
        return <Box>{params.row.last_scraped_at ? formatDateTime(params.row.last_scraped_at) : ""}</Box>
      },
    },
    {
      field: "most_recent_published_at",
      headerName: "Most Recently Published",
      sortable: false,
      width: 200,
      renderCell(params) {
        return (
          <Box>{params.row.most_recent_published_at ? formatDateTime(params.row.most_recent_published_at) : ""}</Box>
        )
      },
    },
    {
      field: "total_meetings",
      headerName: "Total Meetings",
      width: 100,
      sortable: false,
      filterable: false,
    },
    {
      field: "spam_enabled",
      headerName: "Spam Enabled",
      renderCell(params) {
        return <Box>{params.row.spam_enabled ? "Yes" : "No"}</Box>
      },
    },
    {
      field: "opp_analysis_enabled",
      headerName: "Opportunity Analysis Enabled",
      renderCell(params) {
        return <Box>{params.row.opp_analysis_enabled ? "Yes" : "No"}</Box>
      },
    },
    {
      field: "speaker_identification_enabled",
      headerName: "Speaker Identification Enabled",
      renderCell(params) {
        return <Box>{params.row.speaker_identification_enabled ? "Yes" : "No"}</Box>
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
      renderCell(params) {
        return <Box>{formatDateTime(params.row.created_at)}</Box>
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 200,
      renderCell(params) {
        return <Box>{formatDateTime(params.row.updated_at)}</Box>
      },
    },
  ]

  const formatFilterParams = (filterParams: FilterParamsSingle) => {
    const newFilterParams = { ...defaultCountyStateFilterParams }
    if (filterParams?.states) {
      newFilterParams.states = [filterParams.states]
    }
    if (filterParams?.counties) {
      newFilterParams.counties = [filterParams.counties]
    }
    return newFilterParams
  }

  const [extendView, setExtendView] = useState<OrganizationExtendedViews>("detailed")

  const { data: organizationsData, isLoading } = useFetchOrganizations({
    page: organizationState.tablePage,
    pageSize: organizationState.pageSize,
    filterParams: formatFilterParams(organizationState.filterParams),
    archiveType: organizationState.archiveTypeFilter,
    nameFilter: organizationState.nameFilterQueryKey,
    spamEnabledFilter: organizationState.spamEnabledFilter,
    autoUpdate: organizationState.autoUpdateFilter,
    channelTypeFilter: organizationState.channelTypeFilter,
    archiveStatusFilter: organizationState.archiveStatusFilter,
    proxyShuffleFilter: organizationState.proxyShuffleFilter,
    videoArchiveIdFilter: organizationState.videoArchiveIdQueryKey,
    oppAnalysisEnabledFilter: organizationState.oppAnalysisEnabledFilter,
    speakerIdentificationEnabledFilter: organizationState.speakerIdentificationEnabledFilter,
    hasContactsFilter: organizationState.hasContactsFilter,
    scope: "search",
    extend: extendView,
    sortBy: organizationState.sortBy,
  })

  const columns: GridColDef[] = useMemo(() => {
    if (extendView === "default") {
      // Filter out the detailed columns while preserving order.
      // Helps for rendering performance when there are a lot of rows.
      return organizationColumns.filter(
        (col) =>
          ![
            "channel_type",
            "state_name",
            "county_name",
            "city_name",
            "total_meetings",
            "last_scraped_at",
            "most_recent_published_at",
            "opp_analysis_enabled",
            "speaker_identification_enabled",
            "video_archive_id",
            "video_archive_type",
            "shuffle_region",
          ].includes(col.field),
      )
    } else {
      return organizationColumns
    }
  }, [organizationColumns, extendView])

  const { setSelectedOrgs, setNameFilterQueryKey, setVideoArchiveIdQueryKey, setTablePage, setSortBy } = unpackSetters(
    organizationState,
    setOrganizationState,
  )

  // Update query params after debouncing the text filters
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setNameFilterQueryKey(organizationState.nameFilter)
      setVideoArchiveIdQueryKey(organizationState.videoArchiveIdFilter)
    }, 700)
    return () => clearTimeout(timeOutId)
  }, [organizationState.nameFilter, organizationState.videoArchiveIdFilter])

  // Reset page when sort changes
  useEffect(() => {
    setTablePage(1)
  }, [organizationState.sortBy])

  const filteredOrgs = useMemo(() => {
    if (organizationsData?.organizations === undefined) {
      return []
    }
    return organizationsData.organizations
  }, [
    organizationState.filterParams,
    organizationsData?.organizations,
    organizationState.autoUpdateFilter,
    organizationState.tablePage,
    organizationState.spamEnabledFilter,
    organizationState.channelTypeFilter,
    organizationState.archiveStatusFilter,
    organizationState.oppAnalysisEnabledFilter,
    organizationState.speakerIdentificationEnabledFilter,
    organizationState.hasContactsFilter,
  ])

  const filteredOrgsTableData = filteredOrgs.map((orgData: IOrganization) => createOrganizationRow(orgData))

  // Build the pagination model based on the state.
  const paginationModel: GridPaginationModel = {
    page: organizationState.tablePage - 1, // DataGrid uses 0-indexed pages
    pageSize: organizationState.pageSize,
  }

  // Update our state when the user changes the grid’s pagination model.
  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setOrganizationState((prev) => ({
      ...prev,
      tablePage: model.page + 1,
      pageSize: model.pageSize,
    }))
  }

  useEffect(() => {
    if (organizationState.pageSize > 500 && organizationsData?.total ? organizationsData.total > 500 : false) {
      setExtendView("default")
    } else {
      setExtendView("detailed")
    }
  }, [organizationState.pageSize, organizationsData?.total])

  const sortingModel: GridSortModel = [
    { field: organizationState.sortBy.name, sort: organizationState.sortBy.method as GridSortDirection },
  ]

  // Update sort state when the grid’s sort model changes.
  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      setSortBy({ name: model[0].field, method: model[0].sort || "asc" })
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" marginY={2} spacing={1}>
        <Grid item xs={1} alignSelf="flex-end">
          <ActionsMenu orgIds={organizationState.selectedOrgs} onActionComplete={() => setSelectedOrgs([])} />
        </Grid>
        <Grid item xs={11}>
          <TableFilters organizationState={organizationState} setOrganizationState={setOrganizationState} />
        </Grid>
      </Grid>
      {extendView === "default" && (
        <Grid item xs={12}>
          <Box sx={{ color: "error.main" }}>
            Note: Detailed columns are hidden when more than 500 rows are displayed.
          </Box>
        </Grid>
      )}
      <Grid container>
        <DataGridPro
          rows={filteredOrgsTableData}
          columns={columns}
          loading={isLoading}
          checkboxSelection
          pagination
          paginationMode="server"
          sortingMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          sortModel={sortingModel}
          onSortModelChange={handleSortModelChange}
          disableColumnMenu
          rowSelectionModel={organizationState.selectedOrgs}
          autoHeight
          onRowSelectionModelChange={(newSelection) => {
            setSelectedOrgs(newSelection as number[])
          }}
          rowCount={organizationsData?.total || 0}
          pageSizeOptions={[10, 25, 50, 100, 500, 1000, 2500]}
          sx={{
            "&.MuiDataGrid-root": { border: "none" },
            fontSize: "1rem",
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
          }}
        />
      </Grid>
    </Grid>
  )
}
